import React from "react"
import PageLayout from "../../components/PageLayout"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"
import ShareButtons from "../../components//ShareButtons"
import { PropTypes } from "prop-types"

const BlogPost = ({ data }) => {
  const { post } = data // data.markdownRemark holds your post data

  const description = post.teaser

  return (
    <PageLayout
      title={post.title}
      metaDescription={description}
      metaImage={getSrc(post.coverImg.gatsbyImageData)}
      actionItem="follow"
      fullWidth={true}
      contentPath={`blog/${post.slug}`}
    >
      <GatsbyImage
        image={post.coverImg.gatsbyImageData}
        alt={post.coverAlt}
        className="blog-post-featured-image"
      />
      <div className="p-4 p-sm-0">
        <header className="blog-post-content">
          <h1>{post.title}</h1>
        </header>
        <div className="blog-post-content">
          <div>
            <div className="row" style={{ marginBottom: "1rem" }}>
              <div className="col-xs-12">
                <span>
                  <b>By Tim Sandberg</b>
                </span>
              </div>
              <div className="col-xs-12 col-sm-6">
                <small>{post.date}</small>
              </div>
              <div className="col-xs-12 col-sm-6 blog-share-buttons mt-3 mt-sm-0">
                <ShareButtons
                  sites={["Twitter", "Facebook", "Email"]}
                  content={`https://www.timsandberg.com/blog/${post.slug}`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="blog-post-content blog-post-body">
          <div
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />
        </div>
        <div className="blog-post-content" style={{ marginBottom: "2rem" }}>
          <div>
            <div className="row">
              <div className="col-xs-6 small" style={{ textAlign: "left" }}>
                <Link to="/blog">
                  <i className="fa fa-arrow-left fa-fw" /> Blog Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default BlogPost

export const query = graphql`
  query BlogPostById($id: String!) {
    post: contentfulBlogPost(id: { eq: $id }) {
      date(formatString: "MMMM Do, YYYY")
      title
      slug
      teaser
      body {
        childMarkdownRemark {
          excerpt
          html
        }
      }
      coverImg {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      coverAlt
    }
  }
`
